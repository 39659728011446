import clsx from "clsx";
import type { ReactNode } from "react";

export default function PagePadding({
  children,
  className,
}: { children: ReactNode; className?: string }) {
  return (
    <div className={clsx("p-4 sm:px-6 lg:px-8 py-10 h-full", className)}>
      {children}
    </div>
  );
}
